.principal.btn {
  border-radius: 25px;
  padding : 10px;
  background-color: #0042FF;
  display: inline-block;
}

.principal.btn:hover {
  background-color: #000000;
  border: solid 1px #000000;
  color: #FFFFFF!important;
}

.btn-bleu-plein{
  border-radius: 25px;
  padding : 10px;
  border: solid 2px #0042FF;
  color: white;
  background-color: #0042FF;
  text-decoration: none;
  display: inline-block;
}

.btn-bleu-plein:hover{
  background-color: black;
  border: solid 2px black;
  color: white;
}

.btn-bleu-vide{
  border-radius: 25px;
  padding : 10px;
  border: solid 2px #0042FF;
  color: #0042FF;
  text-decoration: none;
  display: inline-block;
  background: transparent;
}

.btn-bleu-vide:hover{
  color: white;
  background-color: #0042FF;
}

.btn-gris-a2a2a2-vide{
  border-radius: 25px;
  padding : 10px;
  border: solid 2px #a2a2a2;
  color: #a2a2a2;
  text-decoration: none;
  display: inline-block;
}

.btn-gris-a2a2a2-vide:hover{
  color: white;
  background-color: #a2a2a2;
}

.btn-rouge-vide{
  background-color: transparent;
  border-radius: 25px;
  padding : 10px;
  border: solid 2px red;
  color: red;
  text-decoration: none;
  display: inline-block;
}

.btn-rouge-vide:hover{
  color: white;
  background-color: red;
}

.croix {
  background: transparent;
  border: none;
}

.close {
  border-radius: 25px!important;
  background-color: #ff0000!important;
  border:none!important;
}

.backForm{
  border-radius: 25px;
  border: solid 1px #0042FF;
  width: 30px;
  height: 30px;
  color: #FFFFFF;
  background-color: #0042FF;
}

.backForm:hover{
  border: solid 1px #000000;
  background-color: #000000;
}

.icone {
  color: #0d6efd;
  margin-right: 0.5em;
}

.icone:hover{
  color: #000000;
}

.btn-invisible{
  background: transparent;
  border:none;
}

.hoverList:hover{
  background-color: rgba(0, 185, 255, 0.39);
}

.picto-actions {
  font-size: 22px;
  border-radius: 10px;
  background-color: #0042FF;
  color: #FFFFFF!important;
  padding: 5px;
  margin: 0 5px;
}

.picto-actions-danger {
  font-size: 22px;
  border-radius: 10px;
  background-color: red;
  color: #FFFFFF!important;
  padding: 5px;
  margin: 0 5px;
}

.div-picto:hover p {
  display: block !important;
  position: absolute;
  margin: -64px 0px 0px -22px;
  background: black;
  color: white;
  padding: 5px 5px;
  border-radius: 5px;
}

.buttonCT {
  text-decoration: none;
}
.buttonCT:hover div{
  border-radius: 9px;
  background: #222222!important;
}

.page-link {
  color: #0036ff;
}

.page-item.active .page-link {
  background-color: #0036ff;
  border-color: #0036ff;
}